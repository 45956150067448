<template>
  <div v-if="!tokenChecked">
    <div class="loader-container">
      <div class="row justify-content-center">
        <div class="col-md-4 d-flex justify-content-center">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="tokenIsInvalid">
    <div class="container">
      <OfferNotFound />
    </div>
  </div>
  <div v-else-if="this.data.status === 8 || this.data.status === 7 || this.data.status === 6|| parseDate(data.project_expiry_date) > new Date()">
    <div class="container">
      <OfferRejected
          @openDetailsPage="changePageToDetails"
          :projectId="data.project_id"
          :imageUrl="safeProductThumb"
          :header="data.name"
          :signature="'John (Signature field needed)'"
      />
    </div>
  </div>

  <div v-else-if=" this.data.status === 8 || this.data.status === 7 || this.data.status === 6|| parseDate(data.project_expiry_date) > new Date()">
    <div class="container">
      <OfferRejected
          @openDetailsPage="changePageToDetails"
          :projectId="data.project_id"
          :imageUrl="safeProductThumb"
          :header="data.name"
          :signature="'John (Signature field needed)'"
      />
    </div>
  </div>
  <div v-else>
    <div class="container-fluid fixed-top nav-bottom-border p-0 bg-white">
      <nav class="navbar container navbar-light navbar-expand-md px-md-3">
        <div class="pl-3 d-flex justify-content-center col-12">
          <div class="col-12 text-center">
            <img height="30" src="@/assets/images/framme_logo_black.png"/>
          </div>
        </div>
      </nav>
    </div>

    <div>
      <OfferLandingPage v-if="currentPage === 'OfferLandingPage'"
                        @openDetailsPage="changePageToDetails"
                        :expiryDate="parseDate(data.project_expiry_date)"
                        :projectId="data.project_id"
                        :contactFirstName="data.contact_firstname"
                        :contactLastName="data.contact_lastname ? data.contact_lastname : ''"
                        :contactEmail="data.contact_email"
                        :contactPhone="data.contact_phone"
                        :imageUrl="safeProductThumb"
                        :brmContactFirstName="data.brm.first_name"
                        :brmContactLastName="data.brm.last_name"
                        :brmContactEmail="data.brm.email"
                        :brmContactPhone="data.brm.phone"
                        :brmWebsite="data.brm.website"
                        :header="data.name"
                        :text="data.message_to_customer"
                        :signature="data.brm.first_name"
                        :isMultiInvitation="false"/>
      <OfferDetailsPage
          v-if="currentPage === 'OfferDetailsPage'"
          @toThankYouPage="changePage('ThankYouPage')"
          @rejectionSuccess="handleRejectionSuccess"
          :data="data"
          :isMultiInvitation="false"
      />
      <ThankYou
          v-if="currentPage === 'ThankYouPage'"
          @openDetailsPage="changePageToDetails"
          :projectId="data.project_id"
          :imageUrl="getImageUrl(data.products)"
          :header="data.name"
          :brmContactFirstName="data.brm.first_name"
          :brmContactLastName="data.brm.last_name"
          :brmContactEmail="data.brm.email"
          :brmContactPhone="data.brm.phone"
          :brmWebsite="data.brm.website"
          :signature="'John (Signature field needed)'"
      />
      <ThankYouDeclined
          v-if="currentPage === 'ThankYouPageDeclined'"
          @openDetailsPage="changePageToDetails"
          :projectId="data.project_id"
          :imageUrl="safeProductThumb"
          :header="data.name"
          :brmContactFirstName="data.brm.first_name"
          :brmContactLastName="data.brm.last_name"
          :brmContactEmail="data.brm.email"
          :brmContactPhone="data.brm.phone"
          :brmWebsite="data.brm.website"
          :signature="'John (Signature field needed)'"
      />

    </div>
  </div>
</template>

<script>
import OfferLandingPage from "@/modules/offer/components/OfferLandingPage.vue";
import OfferNotFound from "@/modules/offer/components/OfferNotFound.vue";
import OfferDetailsPage from "@/modules/offer/components/OfferDetailsPage.vue";
import OfferRejected from "@/modules/offer/components/OfferRejected.vue";
import ThankYou from "@/modules/offer/components/ThankYou.vue";
import ThankYouDeclined from "@/modules/offer/components/ThankYouDeclined.vue";

export default {
  name: 'Offer',
  components: {ThankYouDeclined, OfferRejected, OfferNotFound, OfferLandingPage, OfferDetailsPage, ThankYou},
  data() {
    return {
      offerJustConfirmed: false,
      tokenChecked: false,
      tokenIsInvalid: false,
      isRejected: false,
      data: {},
      currentPage: 'OfferLandingPage',
    };
  },
  computed: {
    safeProductThumb() {
      return this.data.products && this.data.products.length > 0 && this.data.products[0].product_thumb ? this.data.products[0].product_thumb : '';
    }
  },
  created() {
    // console.log("Offers component created");
    this.getOffer();
  },
  methods: {
    getImageUrl(products) {
      for (const product of products) {
        if (product.branding_methods) {
          for (const method of product.branding_methods) {
            if (method.mockup_file) {
              return method.mockup_file;
            }
          }
        }
      }
      return products.length > 0 ? products[0].product_thumb : '';
    },
    getOffer() {
      // console.log("Fetching campaign with token");

      const api_url = `${process.env.VUE_APP_API_URL}/api/projects/open/get-by-token/${this.$route.params.token}`;
      this.$http.get(api_url)
          .then(response => {
            if (response.data.favicon) {
              document.querySelector('link[rel="icon"]').href = response.data.favicon;
            } else {
              document.querySelector('link[rel="icon"]').href = `${process.env.BASE_URL}favicon.ico`;
            }
            if (response.data.title) {
              document.getElementById("store-title").innerText = response.data.title;
              document.getElementById("og-store-title").content = response.data.title;
            }
            if (response.data.description) {
              document.getElementById("store-description").content = response.data.description;
              document.getElementById("og-store-description").content = response.data.description;
            }
            this.data = response.data;
            this.$i18n.locale = response.data.language;
            this.tokenChecked = true;
          })
          .catch(error => {
            // console.log(error)
            if (error.response.data && error.response.data.language) {
              this.$i18n.locale = error.response.data.language;
            }
            this.tokenIsInvalid = true;
            this.tokenChecked = true;
          });
    },
    parseDate(date_str) {
      if(date_str) {
        let dateParts = date_str.replaceAll('-', '/').split('/');
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      } else {
        return '';
      }

    },
    changePage(page) {
      this.currentPage = page;
    },
    changePageToDetails() {
      this.currentPage = 'OfferDetailsPage';
    },
    handleRejectionSuccess() {
      this.currentPage = 'ThankYouPageDeclined';
      // console.log(this.currentPage)
    }
  },
};
</script>

<template>
  <div class="container pt-4 pb-4">
    <div class="row animated-container mt-0">
      <div class="col-12 col-md-6 mb-4">
        <img v-if="imageUrl" :src="imageUrl" class="w-100">
      </div>
      <div class="col-12 col-md-6 align-self-center">
        <div class="row">
          <div class="col-6">
            <SamLabel margin-bottom-class="mb-0" label="Offer ID"/>
            <SamSpan margin-bottom-class="mb-0" :text="projectId"/>
          </div>
          <div class="col-6">
            <SamLabel margin-bottom-class="mb-0" label="Offer expiry date"/>
            <SamSpan margin-bottom-class="mb-0" :text="expiryDate"/>
          </div>
        </div>
        <div class="original-content mt-3">
          <h1 :style="headerStyles">{{header}}</h1>
          <div v-html="formattedText"></div>
          <div class="mt-md-3">{{signature}}</div><br>
          <div class="row">
            <div class="col-12 col-md-12 mt-2">
              <SamPrimaryButton text="View offer" @buttonAction="selectPage" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <SamLabel margin-bottom-class="mb-0" label="Framme contact details"/>
            <SamSpan margin-bottom-class="mb-0" :text="brmContactFirstName + ' ' + brmContactLastName"/>
            <SamSpanLink margin-bottom-class="mb-0" :text="brmContactEmail" :link="'mailto:' + brmContactEmail"/>
            <SamSpan margin-bottom-class="mb-0" :text="brmContactPhone"/>
            <SamSpanLink margin-bottom-class="mb-0" :text="brmWebsite ? brmWebsite.replace(/^www\./, '')  : ''" :link="'https://' + brmWebsite"/>
          </div>
          <div class="col-6">
            <SamLabel margin-bottom-class="mb-0" label="Offer provided to"/>
            <SamSpan margin-bottom-class="mb-0" :text="contactFirstName + ' ' + contactLastName"/>
            <SamSpan margin-bottom-class="mb-0" :text="contactPhone"/>
            <SamSpanLink margin-bottom-class="mb-0" :text="contactEmail" :link="'mailto:' + contactEmail"/>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SamLabel from "@/components/SamLabel.vue";
import SamSpan from "@/components/table/SamSpan.vue";
import SamPrimaryButton from "@/components/SamPrimaryButton.vue";
import SamSpanLink from "@/components/SamSpanLink.vue";

export default {
  name: 'OfferLandingPage',
  components: {SamSpanLink, SamPrimaryButton, SamSpan, SamLabel},
  props: ['expiryDate', 'imageUrl', 'header', 'text', 'signature', 'headerStyles', 'buttonStyles', 'isMultiInvitation', 'projectId', 'contactFirstName', 'contactLastName', 'contactEmail', 'contactPhone', 'brmContactFirstName', 'brmContactLastName', 'brmContactEmail', 'brmContactPhone', 'brmWebsite'],
  computed: {
    formattedText() {
      return this.text ? this.text.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
    },
  },
  methods: {
    selectPage() {
      this.$emit('openDetailsPage');
    },
  }
}
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.animated-container {
  padding-top: 0;
}

@media (max-width: 767.98px) {
  .animated-container {
    overflow-y: auto;
  }
}
</style>
